import { object } from './_obj.js';
import { getEasing } from './_easing.js';
let Block = {
	top: 0,
	show: 0
}

/*
座標
elem.getBoundingClientRect()
*/
let $html 		= document.documentElement;
let $this;
const clips = [9, 49.235, 88.4, 49.235];
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		$this.main = document.querySelector("main .main_inner");
		$this.cover = document.querySelector(".cover");
		$this.dot 	= document.querySelector("#ex .ex1");
		this.checkBlock();
		this.checkScroll();
		setInterval(function(){
			$this.checkBlock();
			$this.checkScroll();
		}, 100);
		document.addEventListener("scroll", ()=>{
			$this.topD = window.pageYOffset;
			$this.checkScroll();
		}, false);
	}
	
	checkScroll(){
		$this.btmD = $this.topD + window.innerHeight;
		for(let index in $this.animations){
			if( $this.btmD >= $this.animations[index].top[1] ){
				$this.activeAnimate(index);
			}
		}
		let showTop = window.innerHeight*.7;
		let start 	= 0;
		let dot 	= document.querySelector("#ex .ex1");
		let dotH 	= dot.clientHeight;
		let docH 	= document.querySelector("main").clientHeight;
		let exH 	= document.querySelector("#ex .ex0").clientHeight;
		let exTop 	= document.querySelector("#ex").offsetTop;
		//console.log(exTop);
		let exBtm 	= docH - (exH + exTop);
		let btmPer 	= exBtm/docH*100;
		let topPer 	= exTop/docH*100;
		let sidePer = ( (document.querySelector("#ex").offsetLeft + document.querySelector("#ex .ex0").offsetLeft) / window.innerWidth)*100;
		//console.log(dotH);
		
		if( $this.topD < showTop){
			if( $this.topD >= start ){
				let progress = ($this.topD - start)/showTop;
				//console.log(progress, topPer, btmPer);
				let topClip, btmClip, sideClip;
				topClip = topPer*(1 - progress);
				btmClip = btmPer*(1 - progress*.4);
				sideClip = sidePer*(1 - progress);
				$this.main.style.clipPath = `inset(${topClip}% ${sideClip}% ${btmClip}% ${sideClip}%)`;
				$this.cover.style.opacity = (1 - progress);
				//dot
				//let s = 1 + progress*50;
				let startY 		= exH + exTop;
				let currentY 	= exBtm - btmClip/100*docH;
				let diffY 		= currentY - startY;
				//console.log(startY, currentY, diffY);
				
				let diffP = (100 - sideClip*2)/100*1.363;
				let s = (diffP*(window.innerWidth+dot.clientWidth))/dot.clientWidth;
				//diffY = diffY*diffP;
				
				//console.log(diffP, diffP*window.innerWidth);
				//s = 1;
				$this.dot.style.transform 	= `scale(${s}, ${s}) translate3d(0, ${ currentY/s }px, 0)`;
				$this.dot.style.opacity 	= (1 - progress*2);
			}
			if( $html.classList.contains("scrolled") ){
				$html.classList.remove("scrolled");
			}
		}else{
			$this.main.style.clipPath = ``;
			$this.cover.style.opacity = 0;
			if( !$html.classList.contains("scrolled") ){
				$html.classList.add("scrolled");
			}
		}
		if($this.topD <= 0){
			$this.main.style.clipPath = `inset(${topPer}% ${sidePer}% ${btmPer}% ${sidePer}%)`;
		}
	}
	activeAnimate(i){
		let $a = $this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		const Animate = {
			top: []
		}
		$this.blockA = document.querySelectorAll(".a");
		$this.animations = [];
		$this.blockA.forEach( (item) => {
			let _a = object(Animate);
			_a.top = $this.getTop(item, window.innerHeight/3);
			$this.animations.push(_a);
		});
	}
	getTop($dom, offset){
		let t = Math.floor( $dom.getBoundingClientRect().top + window.pageYOffset );
		return [ t, Math.floor( t + offset )]
	}
	
	scrollToPos(posY){
		console.log("scroll to > ", posY);
		window.scrollTo({
			top: posY,
			behavior: "smooth"
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}