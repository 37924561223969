import { preload } from './lib/_preload.js';
import { Scroll } from './lib/_scroll.js';
import { movie } from './lib/_movie.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';

let $this;
export class Framework {
	constructor(){
		$this = this;
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
		console.log(this.pageinfo);
		if($this.pageinfo.path != "/thanks.html"){
			$this.scroll = new Scroll();
		}
		
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.changeContent();
		}
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		movie.init();
		let $body = document.querySelector("body");
		if( $body.classList.contains("disable") ){
			$body.classList.remove("disable");
		}
		common.resize();
		common.nav();
		this.reset();
		this.nav();
	}
	
	reset(){
		this.canClick = true;
	}
}