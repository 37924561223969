let $html 		= document.documentElement;

export let movie = {
	player: "",
	id: "",
	init: function(){
		let tag = document.createElement('script');
		
		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		
		window.onYouTubeIframeAPIReady = function() {
			console.log("YouTube API is Ready");
			movie.nav();
		}
	},
	nav: function(){
		let $navMovies = document.querySelectorAll("[data-movie-id]");
		$navMovies.forEach( ($nav) => {
			$nav.removeEventListener("click", movie.getID, false);
			$nav.addEventListener("click", movie.getID, false);
		});
		let $closes = document.querySelectorAll("[data-modal-close]");
		$closes.forEach( ($close) => {
			$close.removeEventListener("click", movie.closeModal, false);
			$close.addEventListener("click", movie.closeModal, false);
		});
	},
	getID: function(){
		let $thisnav = this;
		let url = $thisnav.getAttribute("data-movie-id");
		movie.id = url;
		console.log(movie.id);
		movie.setPlayer();
	},
	setPlayer: function(){
		let $modal = document.querySelector("[data-modal]");
		let $player = $modal.querySelector("#player");
		$player.remove();
		$modal.querySelector(".movie").innerHTML += `<div id="player"></div>`;
		movie.player = new YT.Player('player', {
			height: '360',
			width: '640',
			videoId: movie.id,
			playerVars 	: {
				autohide	: 1,
				controls	: 1,
				frameborder : 0,
				rel			: 0,
				start		: 0,
				showinfo 	: 0,
				disablekb	: 1,
				wmode		: "transparent",
				autoplay	: 0,
				mute 		: 0,
				playsinline	: 1,
				enablejsapi	: 1,
				iv_load_policy: 3,
				origin: location.protocol + '//' + location.hostname + "/"
			},
			events: {
				'onReady': onPlayerReady,
				'onStateChange': onPlayerStateChange
			}
		});
		function onPlayerReady(){
			movie.openModal();
		}
		function onPlayerStateChange(){
			
		}
	},
	openModal: function(){
		if( !$html.classList.contains("show_modal") ){
			$html.classList.add("show_modal");
			movie.player.playVideo();
		}
	},
	closeModal: function(){
		movie.player.pauseVideo();
		if( $html.classList.contains("show_modal") ){
			$html.classList.remove("show_modal");
		}
	}
}