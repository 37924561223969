import { shareSNS } from './lib/_share.js';
import { ScrollTo } from './lib/_scroll.js';
let $html 		= document.documentElement;
export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
		window.addEventListener("keyup", function(e){
			if(e.keyCode === 65){
				let all = document.querySelectorAll(".a");
				all.forEach(function(a){
					a.classList.remove("animate");
				});
			}
		});
	},
	nav: ()=>{
		let $menu = document.querySelector("[data-menu]");
		$menu.removeEventListener("click", common.toggleNav, false);
		$menu.addEventListener("click", common.toggleNav, false);
		
		let $close = document.querySelector("[data-menu-close]");
		$close.removeEventListener("click", common.toggleNav, false);
		$close.addEventListener("click", common.toggleNav, false);
		
		let $scrolls = document.querySelectorAll(`[data-scroll]`);
		$scrolls.forEach( ($s) => {
			$s.removeEventListener("click", common.moveTo, false);
			$s.addEventListener("click", common.moveTo, false);
		});
		
		let $shares = document.querySelectorAll("[data-share]");
		$shares.forEach( ($share) => {
			$share.removeEventListener("click", common.share, false);
			$share.addEventListener("click", common.share, false);
		});
		//form
		//let $form = document.querySelector("form#contact_form");
		//$form.addEventListener("submit", common.afterSubmit, false);
		
		//shareSNS.nav();
		
		/*
		let $scroll = document.querySelector(".btn_scroll");
		$scroll.addEventListener("click", function(){
			let id 		= this.getAttribute("data-id");
			let posY 	= document.querySelector("#" + id).getBoundingClientRect().top;
			common.scrl.scrollToPos(posY);
		})
		*/
	},
	afterSubmit: function(e){
		e.preventDefault();
		let $contact = document.querySelector("#contact");
		$contact.classList.add("form_complete");
	},
	share: function(e){
		e.preventDefault();
		let type 	= this.getAttribute("data-share");
		let url 	= location.href;
		if (type === "twitter" ){ //twitter
			let txt = "";
			window.open(
				'https://twitter.com/share?url=' + encodeURIComponent(url) + "&text=" + encodeURIComponent(txt),
				'twittersharedialog', 'width=626,height=436');
		}else if( type === "facebook" ){ //facebook
			window.open(
				'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
				'facebooksharedialog', 'width=626,height=436');
		}
	},
	toggleNav: function(){
		if( !$html.classList.contains("menu") ){
			$html.classList.add("menu");
		}else{
			$html.classList.remove("menu");
		}
	},
	moveTo: function(e){
		e.preventDefault();
		let id = this.getAttribute("data-id");
		let domID = "#" + id;
		let posY = document.querySelector(domID).getBoundingClientRect().top + window.pageYOffset;
		if(id === "xhro"){
			posY = posY - ( (window.innerHeight - document.querySelector("#xhro").clientHeight) /2);
		}
		ScrollTo.start(posY, 1000);
		if( $html.classList.contains("menu") ){
			$html.classList.remove("menu");
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		addressbarH();
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	}
}
